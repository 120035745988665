import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useLocaleContext } from '../../../context/Locale';
import Layout, { HeroSectionColors, LayoutOptions } from '../../../components/Layout/Layout';
import PageMenu from '../../../components/PageMenu/PageMenu';
import contactUsMenu from '../../../data/page-menus/contact-us';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
// import withPrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import './style.scss';
import { IconTypes } from '../../../components/Icon/Icon';
import InfoBlock from '../../../components/InfoBlock/InfoBlock';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import { ButtonLinkSize } from '../../../components/ButtonLink/ButtonLink';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../data/common/common';

type Props = PageProps & WithTranslation;

const CustomerServiceSupport: React.FC<Props> = (props) => {
  const { t } = props;

  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('customerServiceSupport.seo.title'),
    },
    page: {
      title: t('customerServiceSupport.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const getCustomerServiceSupportListItems = () => {
    const listItems = t(
      'customerServiceSupport.needToMakeHomeWarrantyClaim.currentCustomers.blocks',
      {
        returnObjects: true,
      }
    ) as string[];

    return listItems.map((item, i) => {
      return <li key={`${item}-${i}`}>{item}</li>;
    });
  };

  return (
    <Layout options={layoutOptions} className="CustomerServiceSupport">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60 CustomerServiceSupport__generic-gap">
            <p>{t('customerServiceSupport.introBlock')}</p>
          </section>
          <aside className="column column-33">
            <PageMenu menu={contactUsMenu} />
          </aside>
        </div>
      </div>

      <div className="grid-container">
        <div className="CustomerServiceSupport__generic-gap">
          <h2 className="h3">{t('customerServiceSupport.existingTools.heading')}</h2>
        </div>
        <div className="row row-wrap">
          <div className="column column-40">
            <InfoBlock
              className="CustomerServiceSupport__customer-service-block ContactUs__reduce-icon-size"
              heading={
                <h3 className="h5">
                  {t('customerServiceSupport.existingTools.missedPaymentBlock.heading')}
                </h3>
              }
              icon={IconTypes.ARROW_NEXT}
              buttonOptions={{
                to: getPathFromRouteKey(RouteKeys.CONTACT_US),
                label: t('customerServiceSupport.existingTools.missedPaymentBlock.buttonLabel'),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.SMALL,
                },
                linkOptions: {
                  shouldEnableActiveState: false,
                },
                ariaLabel: t(
                  'customerServiceSupport.existingTools.missedPaymentBlock.ariaButtonLabel'
                ),
              }}
            >
              <p>{t('customerServiceSupport.existingTools.missedPaymentBlock.body')}</p>
            </InfoBlock>
          </div>
          <div className="column column-40">
            <InfoBlock
              className="CustomerServiceSupport__customer-service-block ContactUs__reduce-icon-size"
              heading={
                <h3 className="h5">
                  {t('customerServiceSupport.existingTools.lostYourJobBlock.heading')}
                </h3>
              }
              icon={IconTypes.ARROW_NEXT}
              buttonOptions={{
                to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.NEXTJOB_PROGRAM),
                label: t(`${LocaleNameSpaceKeys.COMMON}:learnMore`),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.SMALL,
                },
                linkOptions: {
                  shouldEnableActiveState: false,
                },
                ariaLabel: t(
                  'customerServiceSupport.existingTools.lostYourJobBlock.ariaButtonLabel'
                ),
              }}
            >
              <p>{t('customerServiceSupport.existingTools.lostYourJobBlock.body')}</p>
            </InfoBlock>
          </div>
        </div>
        <div className="row row-wrap">
          <div className="column column-40">
            <InfoBlock
              className="CustomerServiceSupport__customer-service-block ContactUs__reduce-icon-size"
              heading={
                <h3 className="h5">
                  {t('customerServiceSupport.existingTools.mortgageRenewalBlock.heading')}
                </h3>
              }
              icon={IconTypes.ARROW_NEXT}
              buttonOptions={{
                to: getPathFromRouteKey(RouteKeys.CONTACT_US),
                label: t('customerServiceSupport.existingTools.mortgageRenewalBlock.buttonLabel'),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.SMALL,
                },
                linkOptions: {
                  shouldEnableActiveState: false,
                },
                ariaLabel: t(
                  'customerServiceSupport.existingTools.mortgageRenewalBlock.ariaButtonLabel'
                ),
              }}
            >
              <p>{t('customerServiceSupport.existingTools.mortgageRenewalBlock.body')}</p>
            </InfoBlock>
          </div>
          <div className="column column-40">
            <InfoBlock
              className="CustomerServiceSupport__customer-service-block ContactUs__reduce-icon-size"
              heading={
                <h3 className="h5">
                  {t('customerServiceSupport.existingTools.mortgagePrepaymentBlock.heading')}
                </h3>
              }
              icon={IconTypes.ARROW_NEXT}
              buttonOptions={{
                to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.PREPAYMENT_CALCULATOR),
                label: t(
                  'customerServiceSupport.existingTools.mortgagePrepaymentBlock.buttonLabel'
                ),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.SMALL,
                },
                linkOptions: {
                  shouldEnableActiveState: false,
                },
                ariaLabel: t(
                  'customerServiceSupport.existingTools.mortgagePrepaymentBlock.ariaButtonLabel'
                ),
              }}
            >
              <p>{t('customerServiceSupport.existingTools.mortgagePrepaymentBlock.body')}</p>
            </InfoBlock>
          </div>
        </div>
        <div className="row row-wrap">
          <div className="column column-40">
            <InfoBlock
              className="CustomerServiceSupport__customer-service-block ContactUs__reduce-icon-size"
              heading={
                <h3 className="h5">
                  {t('customerServiceSupport.existingTools.higherInterest.heading')}
                </h3>
              }
              icon={IconTypes.ARROW_NEXT}
              buttonOptions={{
                to: getPathFromRouteKey(
                  RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_TOOLS_RESOURCES
                ),
                label: t('customerServiceSupport.existingTools.higherInterest.buttonLabel'),
                iconOptions: {
                  icon: IconTypes.ARROW_NEXT,
                },
                styleOptions: {
                  isInline: true,
                  size: ButtonLinkSize.SMALL,
                },
                linkOptions: {
                  shouldEnableActiveState: false,
                },
                ariaLabel: t('customerServiceSupport.existingTools.higherInterest.ariaButtonLabel'),
              }}
            >
              <p>{t('customerServiceSupport.existingTools.higherInterest.body')}</p>
            </InfoBlock>
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div className="row row-wrap">
          <div className="column column-40">
            <InfoBlock
              className="CustomerServiceSupport__customer-service-block ContactUs__reduce-icon-size"
              heading={
                <h3 className="h5">
                  {t('customerServiceSupport.needToMakeHomeWarrantyClaim.contactUsBlock.heading')}
                </h3>
              }
              icon={IconTypes.PHONE}
            >
              <p>
                {t(
                  'customerServiceSupport.needToMakeHomeWarrantyClaim.contactUsBlock.openingHours'
                )}
              </p>
              <p>
                {t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)}:&nbsp;
                <ExternalLink
                  href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                  aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                    CommonData.tollFreeCustomerServiceNumber.mortgageServicing
                  }`}
                >
                  {CommonData.tollFreeCustomerServiceNumber.mortgageServicing}
                </ExternalLink>
              </p>
              <p>
                T:&nbsp;
                <ExternalLink
                  href={`tel:${CommonData.telephoneNumber.toronto.first}`}
                  aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:phoneNumber`)} ${
                    CommonData.telephoneNumber.toronto.first
                  }`}
                >
                  {CommonData.telephoneNumber.toronto.first}
                </ExternalLink>
              </p>
              <p>
                F:&nbsp;
                <ExternalLink
                  href={`tel:${CommonData.faxNumber.complaintsDepartment}`}
                  aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:fax`)} ${
                    CommonData.faxNumber.complaintsDepartment
                  }`}
                >
                  {CommonData.faxNumber.complaintsDepartment}
                </ExternalLink>
              </p>
            </InfoBlock>
          </div>
          <div className="column column-40">
            <InfoBlock
              className="CustomerServiceSupport__customer-service-block"
              heading={
                <h3 className="h5">
                  {t('customerServiceSupport.needToMakeHomeWarrantyClaim.currentCustomers.heading')}
                </h3>
              }
              icon={IconTypes.RESCUE_RING}
            >
              <ul>{getCustomerServiceSupportListItems()}</ul>
            </InfoBlock>
          </div>
        </div>
        <p className="small">{t('customerServiceSupport.footerNote')}</p>
      </div>
    </Layout>
  );
};

// export default withPrivateRoute(
//   withTranslation(LocaleNameSpaceKeys.CONTACT_US)(CustomerServiceSupport)
// );

export default withTranslation(LocaleNameSpaceKeys.CONTACT_US)(CustomerServiceSupport);
